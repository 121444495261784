import { render, staticRenderFns } from "./mobile.vue?vue&type=template&id=69c63b48&scoped=true"
import script from "./mobile.vue?vue&type=script&lang=js"
export * from "./mobile.vue?vue&type=script&lang=js"
import style0 from "./mobile.vue?vue&type=style&index=0&id=69c63b48&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c63b48",
  null
  
)

export default component.exports