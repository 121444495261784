<template>
	<div class="page">
		<video class="video-bg" src="https://leaderboard.metacene.io/img/screen_video.mp4" @play="videoLoaded = true" autoplay loop muted
			:controls="false"></video>
		<video class="video-bg" :style="{ opacity: currentBtn == 0 ? 0 : 1 }" src="https://leaderboard.metacene.io/img/shade_video.mp4"
			autoplay loop muted :controls="false"></video>

		<div class="fix-header">
			<div class="top-line-cell">
				<span class="d-text">BUILDING A NEXT-GEN BLOCKCHAIN MMORPG HOMELAND FOR MASS PLAYERS</span>
				<img src="@/assets/images/decorator_line.png" alt="" class="top-line" />
			</div>

			<div class="container-warpper">
				<a class="logo" href="https://www.metacene.io/"><img src="@/assets/images/logo_white.png" /></a>
			</div>
		</div>

		<div class="btn-box" v-if="videoLoaded">
			<div :class="['btn', `btn${index}`, { active: actived(index) }]" v-for="index in 3" :key="index"
				@click="changeActive(index)" @mouseenter="tempBtn = index" @mouseleave="tempBtn = 0">
				<div class="btn-circle"></div>
				<img class="btn-icon" :src="actived(index)
						? require(`@/assets/rank-images/icon${index}_active.png`)
						: require(`@/assets/rank-images/icon${index}.png`)
					" />
				<img class="btn-text" :src="actived(index)
						? require(`@/assets/rank-images/text${index}_active.png`)
						: require(`@/assets/rank-images/text${index}.png`)
					" />
			</div>
		</div>

		<div class="icon_home_left_line" src="@/assets/images/home_left_line.png" />
		<div class="icon_home_right_line" src="@/assets/images/home_right_line.png" />

		<div class="bottom-line-cell">
			<span class="d-text">BUILDING A NEXT-GEN BLOCKCHAIN MMORPG HOMELAND FOR MASS PLAYERS</span>
			<img src="@/assets/images/decorator_line.png" class="top-line" />
			<img v-if="currentBtn == 0" src="@/assets/rank-images/tip.png" class="bottom-text" />
		</div>

		<!-- home -->
		<div class="words home" :style="{ opacity: currentBtn == 0 ? 1 : 0, zIndex: currentBtn == 0 ? 1 : -1 }">
			<img src="@/assets/rank-images/title.png" class="big-txt" />
			<img src="@/assets/rank-images/subheading.png" class="small-txt" />
		</div>

		<!-- activity -->
		<div class="words activity" :style="{ opacity: currentBtn == 1 ? 1 : 0, zIndex: currentBtn == 1 ? 1 : -1 }">
			<div class="title">
				ACTIVITY REWARDS<img src="@/assets/rank-images/arrow.png" />
			</div>
			<div class="module">
				<p class="big">
					Season Rewards: <span class="highlight">500,000 $MAK</span> per server
				</p>
        <p style="font-size: 0.28rem;">(MAN holder benefits are not included)</p>
			</div>
			<div class="module">
				<p class="big">Exclusive MAN Holders Privilege</p>
				<p class="small">
					<img src="@/assets/rank-images/rhombus.png" />
					<span>Players with <span class="highlight">one MAN card</span> can
						receive additional <span class="highlight">4%</span> of the base
						rewards on the Leaderboard.With <span class="highlight">two cards</span>,you can earn
						<span class="highlight">8%</span>,and the more MAN cards you
						have,the higher your rewards -
						<span class="highlight">no upper limit!</span></span>
				</p>
				<p class="small">
					<img src="@/assets/rank-images/rhombus.png" />
					<span>Each Man Card must be staked in the game backpack for at
						least <span class="highlight">20</span> days to be counted in the
						activity.</span>
				</p>
			</div>
			<div class="module">
				<p class="small">Tips</p>
        <p class="smaller">
          <img src="@/assets/rank-images/rhombus.png" />
          <span>Final rewards of the leaderboard will be given to the top 500 players in the season's total points,distributed within 7 working days after the season ends,users can claim through website <a style="text-decoration:underline;color: rgba(94,155,233,1)" href="https://tmak.metacene.io/">https://tmak.metacene.io/</a></span>
        </p>
        <p class="smaller">
          <img src="@/assets/rank-images/rhombus.png" />
          <span>Tie Resolution:In case of a tie,the $MAK rewards will be equally divided.</span>
        </p>
			</div>
		</div>

		<!-- charts -->
		<div class="words charts" :style="{ opacity: currentBtn == 2 ? 1 : 0, zIndex: currentBtn == 2 ? 1 : -1 }">
			<charts />
<!--      <img src="@/assets/rank-images/comming_soon.png">-->
		</div>

		<!-- rules -->
		<div class="words rules" :style="{ opacity: currentBtn == 3 ? 1 : 0, zIndex: currentBtn == 3 ? 1 : -1 }">
			<div class="title">RULES<img src="@/assets/rank-images/arrow.png" /></div>
			<p class="module small">
        <span class="big">Start Time:</span>
        <br>
        <span>September 13,10:00 AM(UTC+8)</span>
			</p>
			<p class="module small">
        <span class="big">Ranking Composition:</span>
        <br />
        <span>MUD consumption(<span class="highlight">40%</span>)</span>,Ferron consumption(<span class="highlight">30%</span>),
        <br/>
        <span>Excite Value Rank(<span class="highlight">20%</span>)</span>,Power Rank(<span class="highlight">10%</span>)
			</p>
      <p class="module small">
        <span class="big">Duration:</span>
        <br />
        <span>This season lasts for <span class="highlight">28 days</span></span>
      </p>
			<p class="module small">
				<span class="big">Data Update Time:</span>
        <br />
        <span>Daily update:MUD consumption,Ferron consumption weekly update:Excite Value Rank,Power Rank(data collected every Sunday at 23:59:59)</span>
			</p>
      <a href="https://www.metacene.io/news/0c50145ddc2f4633942ddb526b69b131?tag=feature"><img class="detail" src="@/assets/rank-images/rule_detail.png" ></a>
		</div>
	</div>
</template>

<script>
import charts from './charts/charts.vue';
export default {
	name: "pagePC",
	components: { charts },
	data() {
		return {
			tempBtn: 0,
			currentBtn: 0, // 0: home, 1: activity, 2: charts, 3: rules
			videoLoaded: false,
		};
	},
	methods: {
		actived(i) {
			return this.tempBtn == i || this.currentBtn == i;
		},
		changeActive(index) {
			this.currentBtn = this.currentBtn == index ? 0 : index;
			localStorage.setItem('currentStatus', this.currentBtn);
		},
	},
	mounted() {
		// const currentStatus = localStorage.getItem('currentStatus');
		// if(currentStatus) {
		// 	this.currentBtn = currentStatus;
		// }
	}
};
</script>

<style lang="scss" scoped>
// page-container
.page {
	width: 100vw;
	height: 100vh;
	position: relative;
	background-color: black !important;

	>.video-bg {
		position: absolute;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: right;
		// min-height: 800px;

		transition: opacity 1.5s;
	}
}

// change animation
.btn-box {
	width: 1px;
	height: 1px;
	background: #000;

	position: absolute;
	top: 5.6667rem;
	right: 6.6rem;

	// @include down(lg) {
	// 	display: none;
	// }

	@keyframes float {
		0% {
			transform: translate3d(0, 0, 0);
		}

		50% {
			transform: translate3d(0, -30px, 0);
		}

		100% {
			transform: translate3d(0, 0, 0);
		}
	}

	@keyframes rotate {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}

	>.btn.active {
		background-image: url("@/assets/rank-images/btn_active.png");

		>.btn-circle {
			width: 2.2133rem;
			height: 1.96rem;
			background-image: url("@/assets/rank-images/circle_active.png");
		}
	}

	>.btn1.active>.btn-icon {
		width: 1.1rem;
		height: 1.1rem;
	}

	>.btn2.active>.btn-icon {
		width: 0.8933rem;
		height: 0.9333rem;
	}

	>.btn3.active>.btn-icon {
		width: 1rem;
		height: 1.04rem;
	}

	>.btn {
		width: 3.3467rem;
		height: 3.3467rem;
		background: url("@/assets/rank-images/btn.png") no-repeat;
		background-size: contain;
		background-position: center;
		cursor: pointer;
		position: absolute;

		display: flex;
		justify-content: center;
		align-items: center;

		>.btn-circle {
			width: 1.9333rem;
			height: 1.72rem;
			background: url("@/assets/rank-images/circle.png") no-repeat;
			background-size: contain;
			animation: rotate 16s linear infinite;
		}

		>.btn-icon {
			position: absolute;
			object-fit: contain;
		}

		>.btn-text {
			background-size: contain;
			position: absolute;
			transform: translateY(0.4667rem);
		}
	}

	>.btn1 {
		top: -2.1333rem;
		left: -4rem;
		animation: float 4s ease-in-out infinite both;

		>.btn-icon {
			width: 1.0267rem;
			height: 1.0267rem;
		}

		>.btn-text {
			width: 2.6533rem;
			height: 0.3733rem;
		}
	}

	>.btn2 {
		top: -3.7333rem;
		right: -5.3333rem;
		animation: float 4s 2s ease-in-out infinite both;

		>.btn-icon {
			width: 0.8533rem;
			height: 0.9067rem;
		}

		>.btn-text {
			width: 1.6133rem;
			height: 0.3733rem;
		}
	}

	>.btn3 {
		bottom: -4.8rem;
		right: -5.4667rem;
		animation: float 4s ease-in-out infinite both;

		>.btn-icon {
			width: 0.9467rem;
			height: 1rem;
		}

		>.btn-text {
			width: 1.6133rem;
			height: 0.3733rem;
		}
	}
}


// #region words
.words {
	font-family: "RobotoMono-Medium";
	position: absolute;
	left: 1.54rem;
	top: 2.2rem;
	color: #fff;
	transition: opacity 1s;

	overflow: auto;
	height: calc(100vh - 3rem);
	&::-webkit-scrollbar {
		width: 0;
	}

	// @include down(lg) {
	// 	display: none;
	// }

	>.title {
		font-family: "RobotoMono-Bold";
		font-size: 1.1rem;
		font-weight: bold;
		line-height: 1;
		margin-bottom: 0.05rem;

		>img {
			margin-left: 0.05rem;
			width: 0.8rem;
			object-fit: contain;
		}
	}

	>.module {
		width: 11.5rem;
		padding: 0.35rem 0;

		&:not(:last-of-type) {
			border-bottom: 2px solid rgba(255, 255, 255, 0.2);
		}
	}

	span.highlight {
		font-weight: bold;
		color: #ffc200;
	}

	.big {
		line-height: 1.5;
		word-break: keep-all;
		font-size: 0.41rem;
	}

	.small {
		word-break: keep-all;
		font-size: 0.28rem;
	}

	.smaller {
		word-break: keep-all;
		font-size: 0.2rem;
	}
}

.words.home {
	top: 2.9rem;

	>.big-txt {
		width: 10.5rem;
		display: block;
		object-fit: contain;
	}

	>.small-txt {
		width: 7rem;
		display: block;
		margin-top: 0.48rem;
		object-fit: contain;
	}
}

.words.charts {
	>img {
		margin-top: 2.2rem;
		width: 9rem;
		object-fit: contain;
	}
}

.words.activity {

	.small,
	.smaller {
		display: flex;

		>img {
			width: 0.22rem;
			height: 0.5rem;
			margin-right: 0.15rem;
			object-fit: contain;
			vertical-align: middle;
		}
	}

	.smaller {
		margin-top: 0.15rem;

		>img {
			height: 0.34rem;
		}
	}
}

.words.rules {
	.detail {
		width: 1.05rem;
		margin-right: 0.5rem;
	}

	.jumpHome {
		width: 5rem;
	}
}

// #endregion

// #region layout
.fix-header {
	position: fixed;
	// z-index: 10;
	left: 0;
	right: 0;
	top: 0;

	>.top-line-cell {
		position: relative;

		>.d-text {
			color: #ffffff7c;
			text-align: center;
			width: 100%;
			position: absolute;
			top: 0;
			font-size: 12px;
		}

		>.top-line {
			width: calc(100% - 2.4rem);
			aspect-ratio: 3058/88;
			display: flex;
			margin: 0.2rem auto 0;
			object-fit: contain;
		}
	}

	>.container-warpper {
		display: flex;
		padding-left: 1.2rem;
		padding-right: 1.2rem;
		position: relative;
		justify-content: flex-end;

		>.logo {
			position: absolute;
			left: 1.563rem;
			top: 0.3rem;
			aspect-ratio: 202/23.32;
			width: 2.99rem;
			object-fit: contain;
			cursor: pointer;
		}
	}
}

.icon_home_left_line {
	position: absolute;
	width: 0.131rem;
	aspect-ratio: 18/1522;
	left: 0.6rem;
	top: 1.6rem;
	object-fit: contain;
	background: url("@/assets/images/home_left_line.png") repeat-y;
	background-size: 100% auto;

	// @include down(lg) {
	// 	display: none;
	// }
}

.icon_home_right_line {
	position: absolute;
	width: 0.131rem;
	aspect-ratio: 18/1522;
	right: 0.6rem;
	top: 1.6rem;
	// background-image: url('@/assets/images/home_right_line.png') repeat-y;

	background: url("@/assets/images/home_right_line.png") repeat-y;
	background-size: 100% auto;

	// @include down(lg) {
	// 	display: none;
	// }
}

.bottom-line-cell {
	position: absolute;
	bottom: 0;

	>.d-text {
		color: white;
		text-align: center;
		width: 100%;
		position: absolute;
		bottom: 0.08rem;
		font-size: 12px;
		color: #ffffff7c;
	}

	>.top-line {
		transform: scaleY(-1);
		width: calc(100% - 2.4rem);
		aspect-ratio: 3058/88;
		display: flex;
		margin: 0.2rem auto 0;
		object-fit: contain;
	}

	>.bottom-text {
		// @include down(lg) {
		// 	display: none;
		// }

		position: absolute;
		top: -20px;
		left: 50%;

		width: 7.6rem;
		object-fit: contain;
		transform: translateX(-50%);
	}
}

// #endregion</style>
